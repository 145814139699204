import { requestGet, requestPost } from '../../axios'

// const dir = '/api'

export default {
  // 用户登录
  login: (params) => requestPost('/auth/user-login/login', params),
  // 用户登录 新
  displayLogin: (params) => requestPost('/auth/user-login/display-login', params),
  // 用户信息
  userInfo: (params) => requestGet('/auth/auth/get-info', params)
  // 退出登录
  // logouts: (params) => requestPost('/auth/logout', params),
}